import './stylesheets/base.sass'
import './stylesheets/BEM_Shared/**/*.sass'
import './stylesheets/BEM_Frontend/**/*.sass'

import 'unpoly/unpoly.js'
import 'unpoly/unpoly.css'
import './javascripts/shared/unpoly_config.js'
import './stylesheets/ext/unpoly.sass'
import './javascripts/shared/unpoly_config.js'

import Rails from 'rails-ujs'
Rails.start()

import './javascripts/frontend/**/*.js'
