import * as htmlToImage from 'html-to-image'

up.compiler('.render-image--button', button => {

  var sourceNode = document.querySelector(button.dataset.source)
  const baseName = button.dataset.filename
  const options = {
    backgroundColor: '#222222',
  }
  
  button.addEventListener('click', event => {
    document.querySelector('body').style.overflow="hidden"
    sourceNode.classList.remove('hidden')
    setTimeout(() => {
      htmlToImage.toPng(sourceNode, options)
        .then(dataUrl => {
          var link = document.createElement('a');
          link.download = `${baseName}.png`;
          link.href = dataUrl;
          link.click();
          sourceNode.classList.add('hidden')
          document.querySelector('body').style.removeProperty("overflow")
        })
    })
  })
})

