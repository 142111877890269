up.compiler('.share-button', element => {

  const button = element.querySelector(element.dataset.buttonSelector)
  const data = {
    url: element.dataset.sharingUrl,
    title: element.dataset.sharingText,
    text: element.dataset.sharingText,
  }
  
  if (typeof navigator.share === 'function') {
    element.classList.remove('hidden')
    button.addEventListener('click', (event) => {
      navigator.share(data)
      event.preventDefault()
    })
  } 
})

